import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useHelpers } from './helpers';
export function useHandlers(state, getters) {
    const vuexStore = useStore();
    const router = useRouter();
    const { actionItemBeingEditedId, actionItemRealtimeValue, actionItems, currentGroupNumber, currentPosterAmountOfTopics, currentPosterId, groups, hasJoinedTheRetro, iceBreakerAnswers, iceBreakerQuestion, invitations, isPreparingIceBreaker, isFull, isJoined, phase, postingStartedAt, progressModal, topicBeingSharedId, topics, turnStartedAt, users, userWritingActionItemId, writingStartedAt } = state;
    const { isModerator, userId } = getters;
    const { abortAllLoadingStatuses, addTopicIfNotExists, addTopicReactionIfNotExists, addUserIfNotExists, clearActionItemWritingStatus, clearSharingStatus, clearTransientPhaseState, fixServerPhaseDirt, patchActionItem, patchGroup, patchTopic, patchUser, removeLoadingStatus, addOwnerToActionItem, removeOwnerToActionItem, setupHandler, sync } = useHelpers(state);
    /** Organization function for grouping the handlers setting up. */
    function setupAllHandlers() {
        setupHandler('retro_joined', (data) => {
            sync(data);
            hasJoinedTheRetro.value = true;
            isJoined.value = true;
        });
        setupHandler('channel_joined', (data) => {
            if (!(data.allowed ?? true)) {
                router.push({ name: 'home' });
                vuexStore.dispatch('flashThenClear', { message: 'unauthorized_retro' });
            }
            sync(data);
            isFull.value = data.isFull ?? false;
            hasJoinedTheRetro.value = false;
            isJoined.value = true;
        });
        setupHandler('cannot_join_channel', (data) => {
            router.push({ name: 'joinRetroError' });
        });
        setupHandler('phase_started', (data) => {
            removeLoadingStatus('next');
            clearTransientPhaseState();
            phase.value = data.phase;
            if (data.writingStartedAt) {
                writingStartedAt.value = data.writingStartedAt;
            }
            currentPosterAmountOfTopics.value = data.amountOfTopics ?? 0;
            if (data.currentPoster) {
                currentPosterId.value = data.currentPoster;
            }
            // `participants` also comes when the posting phase starts.
            // but I don't know why, seems usless also, they are real participants,
            // not any kind of user. Here participants is a computed property and
            // in vuex too.
            /* if (data.participants) { ... } */
            if (data.postingStartedAt) {
                postingStartedAt.value = data.postingStartedAt;
            }
            if (data.turnStartedAt) {
                turnStartedAt.value = data.turnStartedAt;
            }
            if (data.groups) {
                groups.value = data.groups;
            }
            fixServerPhaseDirt();
            if (phase.value === 'discussion' && isModerator.value && groups.value.length > 0) {
                progressModal.value = 'reviewOrNextGroup';
            }
        });
        setupHandler('participant_joined', (data) => {
            // Legacy code marked the user as connected after adding it but there
            // are scenarios where a user joins a retro without being connected
            // for example, when accepting an invite. I will let the server decide
            // if the user is connected or not.
            addUserIfNotExists(data.participant);
            patchUser(data.participant);
        });
        setupHandler('participant_left', (data) => {
            patchUser({ id: data.userId, connected: false });
        });
        setupHandler('participant_kicked', (data) => {
            users.value = users.value.filter((u) => u.id !== data.participantId);
            actionItems.value = actionItems.value.map((a) => {
                return {
                    ...a,
                    owners: a.owners.filter((o) => o.userId !== data.participantId)
                };
            });
            if (data.participantId === userId.value) {
                router.push({ name: 'kickedOutOfRetroError' });
            }
        });
        setupHandler('participant_motivation_submitted', ({ participant }) => {
            patchUser(participant);
        });
        setupHandler('participant_done_writing', ({ participantId }) => {
            removeLoadingStatus('sidebarCta');
            if (participantId === userId.value) {
                clearTransientPhaseState();
            }
            patchUser({ id: participantId, doneWriting: true });
        });
        setupHandler('participant_back_to_writing', ({ participantId }) => {
            removeLoadingStatus('sidebarCta');
            patchUser({ id: participantId, doneWriting: false });
        });
        setupHandler('topic_created', ({ topic }) => {
            // The server serializes the topic without reactions. This is different
            // from the `retro_joined` where topics contains expanded reactions list.
            topics.value.push({ ...topic, reactions: [] });
            removeLoadingStatus('newPrivateTopic');
        });
        setupHandler('topic_deleted', ({ id }) => {
            topics.value = topics.value.filter((t) => t.id !== id);
            removeLoadingStatus('topic');
        });
        setupHandler('topic_updated', ({ topic }) => {
            patchTopic(topic);
            removeLoadingStatus('topic');
        });
        setupHandler('participant_turn_ended', (data) => {
            removeLoadingStatus('next');
            clearTransientPhaseState();
            currentPosterAmountOfTopics.value = data.amountOfTopics ?? 0;
            currentPosterId.value = data.currentPoster;
            turnStartedAt.value = data.turnStartedAt;
        });
        setupHandler('started_sharing_topic', ({ topic, reactions }) => {
            // The server serializes the topic without reactions. This is different
            // from the `retro_joined` where topics contains expanded reactions list.
            addTopicIfNotExists({ ...topic, reactions });
            topicBeingSharedId.value = topic.id;
        });
        setupHandler('canceled_sharing_topic', ({ topic }) => {
            // topic is techincally unnecessary. It was used in the old code to remove
            // topics from other pepole from the list for privacy reasons but...
            // lets eval if it is really necessary later.
            topic;
            clearSharingStatus();
        });
        setupHandler('group_assigned', ({ topic }) => {
            // TODO: what if this kind of events are recived disordered and the topic
            // or the group is not present?
            patchTopic(topic);
            clearSharingStatus();
        });
        setupHandler('group_created', ({ group }) => {
            groups.value.push(group);
        });
        setupHandler('group_positions_updated', (data) => {
            const topicsToUpdate = new Map(data.topics.map((t) => [t.id, t]));
            topics.value = topics.value.map((t) => 
            // Since topics are serialized inconsistentlty depending on the event
            // you must update them using `patchTopic` or using object merge.
            topicsToUpdate.has(t.id) ? { ...t, ...topicsToUpdate.get(t.id) } : t);
        });
        setupHandler('group_deleted', ({ groupId }) => {
            groups.value = groups.value.filter((g) => g.id !== groupId);
        });
        setupHandler('group_voted', ({ groupId, weight }) => {
            removeLoadingStatus('vote');
            // The event does not send the userId but is only sent to this user.
            // The vuex implementation abused this to know its the current user.
            groups.value = groups.value.map((g) => {
                if (g.id === groupId) {
                    const voter = { id: userId.value, weight };
                    const voters = [...g.voters, voter];
                    return { ...g, voters };
                }
                return g;
            });
        });
        setupHandler('group_unvoted', ({ groupId }) => {
            removeLoadingStatus('vote');
            groups.value = groups.value.map((g) => {
                if (g.id === groupId) {
                    const voters = g.voters.filter((v) => v.id !== userId.value);
                    return { ...g, voters };
                }
                return g;
            });
        });
        setupHandler('group_discussion_started', (data) => {
            removeLoadingStatus('next');
            currentGroupNumber.value = data.currentGroupNumber;
            patchGroup(data.group);
        });
        setupHandler('group_time_increased', (data) => {
            patchGroup(data.group);
        });
        setupHandler('action_item_writing_started', (data) => {
            userWritingActionItemId.value = data.userId;
        });
        setupHandler('action_item_writing_canceled', () => {
            clearActionItemWritingStatus();
        });
        setupHandler('action_item_created', (data) => {
            actionItems.value.push(data.actionItem);
            clearActionItemWritingStatus();
        });
        setupHandler('action_item_forcefully_created', (data) => {
            actionItems.value.push(data.actionItem);
            clearActionItemWritingStatus();
        });
        setupHandler('action_item_edit_started', (data) => {
            userWritingActionItemId.value = data.userId;
            actionItemBeingEditedId.value = data.itemId;
        });
        setupHandler('action_item_edit_canceled', () => {
            clearActionItemWritingStatus();
        });
        setupHandler('action_item_update_forcefully_canceled', () => {
            clearActionItemWritingStatus();
        });
        setupHandler('action_item_creation_forcefully_canceled', () => {
            clearActionItemWritingStatus();
        });
        setupHandler('action_item_updated', ({ actionItem }) => {
            patchActionItem(actionItem);
            clearActionItemWritingStatus();
        });
        setupHandler('action_item_forcefully_updated', (data) => {
            patchActionItem(data.actionItem);
            clearActionItemWritingStatus();
        });
        setupHandler('action_item_writing_updated', ({ description }) => {
            actionItemRealtimeValue.value = description;
        });
        setupHandler('action_item_deleted', ({ itemId }) => {
            actionItems.value = actionItems.value.filter((a) => a.id !== itemId);
        });
        setupHandler('owner_assigned', (data) => {
            addOwnerToActionItem(data.actionItemId, data.userId);
        });
        setupHandler('owner_unassigned', (data) => {
            removeOwnerToActionItem(data.actionItemId, data.userId);
        });
        setupHandler('participant_done_voting', ({ participantId }) => {
            removeLoadingStatus('sidebarCta');
            patchUser({ id: participantId, doneVoting: true });
        });
        setupHandler('participant_back_to_voting', ({ participantId }) => {
            removeLoadingStatus('sidebarCta');
            patchUser({ id: participantId, doneVoting: false });
        });
        setupHandler('participant_ok_with_groups', ({ okWithGroups, participantId }) => {
            removeLoadingStatus('sidebarCta');
            patchUser({ id: participantId, okWithGroups });
        });
        setupHandler('ask_ice_breaker', ({ question }) => {
            removeLoadingStatus('icebreaker');
            isPreparingIceBreaker.value = false;
            iceBreakerQuestion.value = question;
        });
        setupHandler('answer_ice_breaker', (answer) => {
            iceBreakerAnswers.value.push(answer);
        });
        setupHandler('self_answer_ice_breaker', () => {
            removeLoadingStatus('icebreaker');
        });
        setupHandler('group_renaming_started', (data) => {
            patchGroup(data.group);
        });
        setupHandler('group_renamed', (data) => {
            patchGroup(data.group);
        });
        setupHandler('group_renaming_canceled', (data) => {
            patchGroup(data.group);
        });
        setupHandler('invited', async (data) => {
            // Needed to update seats information.
            await vuexStore.dispatch('refreshWorkspace');
            removeLoadingStatus('invite');
            // The server is allowing duplicate invites per email but this is not a
            // frontend problem.
            invitations.value = [...invitations.value, ...data.invitations];
            users.value = [...users.value, ...data.participants];
        });
        setupHandler('moderator_added', (data) => {
            patchUser({ id: data.newModeratorId, moderator: true });
        });
        setupHandler('topic_reacted', (data) => {
            // The server also sends the `topicId` separately. But seems useless.
            addTopicReactionIfNotExists(data.reaction);
        });
        setupHandler('topic_unreacted', ({ reaction }) => {
            const topic = topics.value.find((t) => t.id === reaction.topicId);
            if (topic) {
                patchTopic({
                    id: topic.id,
                    reactions: topic.reactions.filter((r) => r.userId !== reaction.userId || r.type !== reaction.type)
                });
            }
        });
        setupHandler('unauthorized_error', () => {
            abortAllLoadingStatuses();
            vuexStore.dispatch('flashThenClear', { message: 'unauthorized_action' });
        });
        setupHandler('error_performing_action', () => {
            abortAllLoadingStatuses();
            vuexStore.dispatch('flashThenClear', { message: 'unexpected_error' });
        });
    }
    return { setupAllHandlers };
}
