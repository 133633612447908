import { Socket } from 'phoenix';
import { getAccessToken, refresh as refreshToken } from '@/helpers/apiClient';
import { isUser } from '@/types/user';
import constants from '@/constants';
import { useHandlers } from './handlers';
import { useHelpers } from './helpers';
export function useActions(state, getters) {
    const { channel, socket, isJoined, sharingMode, topicBeingSharedId, phase } = state;
    const { putLoadingStatus, send } = useHelpers(state);
    const { setupAllHandlers } = useHandlers(state, getters);
    return {
        /**
         * Joins a retro setting up the channel and its handlers.
         */
        join(retroId) {
            refreshToken();
            socket.value = new Socket(constants.SOCKET_URL, {
                params: { token: getAccessToken() }
            });
            socket.value.connect();
            channel.value = socket.value.channel(`retro:${retroId}`);
            setupAllHandlers();
            channel.value.join();
        },
        /** Disconnects the user from the retro. */
        leave() {
            channel.value?.leave();
            socket.value?.disconnect();
            channel.value = null;
            socket.value = null;
            isJoined.value = false;
        },
        /** Sends my motivation. */
        submitMotivation(motivation) {
            send('submit_motivation', { motivation });
        },
        /** Action to make the retro go to the next step/phase. */
        next() {
            // TODO: Would be nice that the server decides what to do next.
            putLoadingStatus('next');
            switch (phase.value) {
                case 'setup':
                    send('start_writing_phase');
                    break;
                case 'writing':
                    send('start_posting_phase');
                    break;
                case 'posting':
                    send('next_poster');
                    break;
                case 'grouping':
                    send('start_voting_phase');
                    break;
                case 'voting':
                    send('start_discussion_phase');
                    break;
                case 'discussion':
                    send('start_review_phase');
                    break;
                case 'review':
                    send('finish');
                    break;
                default:
                    throw new Error('You forgot to handle what happens next? :)');
            }
        },
        /**
         * Dedicated function to transition to the next discussion group.
         */
        nextDiscussionGroup({ minutes }) {
            putLoadingStatus('next');
            send('next_group', { minutes });
        },
        /** Send a message telling tif you are done writing. */
        submitDoneWritingValue(value) {
            putLoadingStatus('sidebarCta');
            if (value) {
                send('done_writing');
            }
            else {
                send('back_to_writing');
            }
        },
        /** Send a message telling if you are done voting. */
        submitDoneVotingValue(value) {
            putLoadingStatus('sidebarCta');
            if (value) {
                send('done_voting');
            }
            else {
                send('back_to_voting');
            }
        },
        /** Send a message telling if you are ok or not with the current groups. */
        submitOkWithGroupsValue(value) {
            putLoadingStatus('sidebarCta');
            send('send_ok_with_groups', { okWithGroups: value });
        },
        /** Creates a personal topic
         */
        createTopic({ type, description }) {
            putLoadingStatus('newPrivateTopic');
            send('create_topic', { type, description });
        },
        /**
         * Updates a topic.
         */
        updateTopic({ topicId, newDescription, newType }) {
            putLoadingStatus('topic');
            send('update_topic', { topicId, newDescription, newType });
        },
        /** Deletes a topic by id. */
        deleteTopic(id) {
            putLoadingStatus('topic');
            send('delete_topic', { topicId: id });
        },
        /**
         * Starts streaming that you are sharing a topic.
         */
        startSharingTopic({ topicId, mode }) {
            send('start_sharing_topic', { topicId });
            sharingMode.value = mode;
        },
        /**
         * Stops streaming that you are sharing a topic.
         */
        stopSharingTopic( /*id: number*/) {
            // Note: Previous code used an id recived as parameter instead of using the
            // one hold in the store.
            send('cancel_sharing_topic', { topicId: topicBeingSharedId.value });
        },
        /**
         * Creates a new group containing the specified topic.
         */
        assignTopicToNewGroup(id) {
            send('initialize_group', { topicId: id });
        },
        /**
         * Assigns a topic to an existing group.
         */
        assignTopicToGroup({ topicId, groupId, position }) {
            send('assign_group', { topicId, groupId, position });
        },
        /** Send a vote for a group. */
        voteGroup({ groupId, weight }) {
            putLoadingStatus('vote');
            send('vote_group', { groupId, weight });
        },
        /** Send an unvote message. */
        unvoteGroup(id) {
            putLoadingStatus('vote');
            send('unvote_group', { groupId: id });
        },
        /** Alert the server that we want to start writing an action item. */
        startWritingActionItem() {
            send('start_writing_action_item');
        },
        /** Tell the server that we don't want to write an action item anymore. */
        stopWritingActionItem() {
            send('cancel_writing_action_item');
        },
        /** Alert the server that we want to start editing an action item. */
        startEditingActionItem(id) {
            send('start_editing_action_item', { itemId: id });
        },
        /** Tell the server that we don't want to edit an action item anymore. */
        stopEditingActionItem() {
            send('cancel_editing_action_item');
        },
        /**
         * Creates an action item for a group.
         */
        createActionItem({ groupId, description }) {
            send('create_action_item', { groupId, description });
        },
        /** Transmit the current text we are writing for the action item. */
        broadcastActionItemWrite({ description }) {
            send('write_action_item_real_time', { description });
        },
        /** Updates an action item in the server. */
        updateActionItem({ itemId, description }) {
            send('update_action_item', { itemId, description });
        },
        /** Deletes an action item in the server. */
        deleteActionItem(id) {
            send('delete_action_item', { itemId: id });
        },
        /** Adds time to the current group being discussed. */
        addTimeToCurrentGroup() {
            send('add_time_to_current_group', { minutes: 1 });
        },
        /**
         * Send an icebreaker question to the server.
         *
         * Puts a loading mark.
         */
        sendIceBreakerQuestion(question) {
            putLoadingStatus('icebreaker');
            send('ask_ice_breaker', { question });
        },
        /**
         * Send an icebreaker answer to the server.
         *
         * Puts a loading mark.
         */
        sendIceBreakerAnswer(text) {
            putLoadingStatus('icebreaker');
            send('answer_ice_breaker', { text });
        },
        /**
         * Force the creation of an action item.
         *
         * This is used to force submitting what other user is writing.
         *
         * Note: The code here was translated from legacy code.
         */
        forceActionItemCreation({ groupId, description }) {
            send('force_create_action_item', { groupId, description });
        },
        /** Cancel the creation of an action item being written in realtime. */
        forceNewActionItemCancellation() {
            send('force_cancel_writing_action_item');
        },
        /**
         * Force the update of an action item.
         *
         * This is used to force submitting what other user is writing, on an
         * existing action item.
         *
         * Note: The code here was translated from legacy code.
         */
        forceActionItemEdition({ itemId, description }) {
            send('force_update_action_item', { itemId, description });
        },
        /** Cancel the update of an existing action item being written. */
        forceExistingActionItemCancellation(itemId) {
            send('force_cancel_editing_action_item', { itemId });
        },
        /** Assigns the current user to an action item as an owner. */
        assignCurrentUserToActionItem(itemId) {
            send('assign_owner', { itemId });
        },
        /** Unassigns the current user from an action item. */
        unassignCurrentUserFromActionItem(itemId) {
            send('unassign_owner', { itemId });
        },
        /** Takes the lock for renaming a group. */
        beginGroupRename(groupId) {
            send('start_renaming_group', { groupId });
        },
        /** Cancels the rename, releaseing the lock. */
        cancelGroupRename(groupId) {
            send('cancel_renaming_group', { groupId });
        },
        /** Submits a new group name and releases the lock. */
        confirmGroupRename(groupId, newName) {
            send('rename_group', { groupId, newName });
        },
        /** Invite workspace members and external emails to the retro. */
        sendInvites(invites) {
            putLoadingStatus('invite');
            send('invite', {
                invites: invites.map((u) => (isUser(u) ? u.id : u.email))
            });
        },
        /** Gives moderator power to a participant. */
        turnUserIntoAModerator(userId) {
            send('make_moderator', { newModeratorId: userId });
        },
        /** Kicks a user out of the retro. */
        kickUser(userId) {
            send('kick_participant', { participantId: userId });
        },
        /** Create a reaction for a topic. */
        createTopicReaction(topicId, type) {
            send('create_topic_reaction', { topicId, type });
        },
        /** Delete a reaction for a topic. */
        deleteTopicReaction(topicId, type) {
            send('delete_topic_reaction', { topicId, type });
        }
    };
}
