import { defineStore } from 'pinia';
import { useState } from './state';
import { useGetters } from './getters';
import { useActions } from './actions';
/**
 * The core state of a retro.
 *
 * Composed mainly by state that must be kept in sync with the server and
 * local but heavily shared state.
 *
 * TODO: Type this store.
 */
export const useRetroStore = defineStore('retro', () => {
    const state = useState();
    const getters = useGetters(state);
    const actions = useActions(state, getters);
    return { ...state, ...getters, ...actions };
});
