import { camelKeys, snakeKeys } from '@/helpers/objects';
export function useHelpers(state /*, getters: Getters */) {
    const { actionItemBeingEditedId, actionItemRealtimeValue, actionItems, channel, config, currentGroupNumber, currentPosterAmountOfTopics, currentPosterId, finishedAt, groups, iceBreakerAnswers, iceBreakerQuestion, invitations, isPreparingIceBreaker, loadingStatuses, phase, postingStartedAt, previousActionItems, progressModal, sharingMode, teamId, topicBeingDeletedId, topicBeingEditedId, topicBeingSharedId, topics, turnStartedAt, users, userWritingActionItemId, writingStartedAt } = state;
    /**
     * Clears some trash data coming from the server.
     *
     * The server is preserving some state data from previous phases like
     * `current_poster_id` and `current_group_number`.
     *
     * If we don't clean these values setting them back to `null` when we are on
     * a phase where they don't make sense, they may affect conditions and break
     * things.
     *
     * This was not a problem for the legacy XState implementation because this
     * data was part of the "context" of specific phase machines.
     *
     * TODO: Clean up this state data on the server where appropriate and remove
     * this frontend workaround.
     */
    function fixServerPhaseDirt() {
        if (phase.value !== 'posting') {
            currentPosterId.value = null;
        }
        if (phase.value !== 'discussion') {
            currentGroupNumber.value = null;
        }
    }
    /**
     * Clear transient state related to specific phases.
     *
     * Useful to clean the state before a new phase start.
     */
    function clearTransientPhaseState() {
        // Remote
        topicBeingSharedId.value = null;
        actionItemBeingEditedId.value = null;
        userWritingActionItemId.value = null;
        currentPosterAmountOfTopics.value = 0;
        actionItemRealtimeValue.value = '';
        currentGroupNumber.value = null;
        currentPosterId.value = null;
        // Local only
        topicBeingEditedId.value = null;
        topicBeingDeletedId.value = null;
        sharingMode.value = null;
        progressModal.value = null;
        loadingStatuses.value.clear();
        isPreparingIceBreaker.value = false;
    }
    /** Utility function to update an action item. */
    function patchActionItem(actionItemPatch) {
        actionItems.value = actionItems.value.map((a) => {
            return a.id === actionItemPatch.id ? { ...a, ...actionItemPatch } : a;
        });
    }
    /** Utility function to update a topic in an immutable way. */
    function patchTopic(topicPatch) {
        topics.value = topics.value.map((t) => {
            return t.id === topicPatch.id ? { ...t, ...topicPatch } : t;
        });
    }
    return {
        fixServerPhaseDirt,
        clearTransientPhaseState,
        patchTopic,
        patchActionItem,
        /**
         * Patches the local state with the one recived from the server.
         *
         * Used by `retro_joined` and `channel_joined` events.
         */
        sync(serverState) {
            actionItems.value = serverState.actionItems ?? [];
            config.value = serverState.config ?? null;
            groups.value = serverState.groups ?? [];
            teamId.value = serverState.teamId ?? null;
            topics.value = serverState.topics ?? [];
            users.value = serverState.users ?? [];
            invitations.value = serverState.invitations ?? [];
            previousActionItems.value = serverState.previousActionItems ?? [];
            // role?
            const { retroState } = serverState;
            const prevPhaseValue = phase.value;
            phase.value = retroState?.phase ?? serverState.phase ?? null;
            // For reconnections where the current phase changed.
            if (prevPhaseValue !== phase.value) {
                clearTransientPhaseState();
            }
            actionItemBeingEditedId.value = retroState?.actionItemBeingEditedId ?? null;
            currentGroupNumber.value = retroState?.currentGroupNumber ?? null;
            currentPosterId.value = retroState?.currentPosterId ?? null;
            finishedAt.value = retroState?.finishedAt ?? null;
            iceBreakerAnswers.value = retroState?.iceBreakerAnswers ?? [];
            iceBreakerQuestion.value = retroState?.iceBreakerQuestion ?? null;
            postingStartedAt.value = retroState?.postingStartedAt ?? null;
            topicBeingSharedId.value = retroState?.topicBeingSharedId ?? null;
            turnStartedAt.value = retroState?.turnStartedAt ?? null;
            userWritingActionItemId.value = retroState?.userWritingActionItemId ?? null;
            writingStartedAt.value = retroState?.writingStartedAt ?? null;
            fixServerPhaseDirt();
        },
        /** Utilty to send events to the server. */
        send(event, data) {
            channel.value?.push(event, snakeKeys(data || {}));
        },
        /** Utility helper function for setting up a single event handler */
        setupHandler(event, handler) {
            channel.value?.on(event, (data) => {
                const parsedData = camelKeys(data);
                handler(parsedData);
            });
        },
        /** Utility function to update a user in an immutable way. */
        patchUser(userPatch) {
            users.value = users.value.map((u) => {
                return u.id === userPatch.id ? { ...u, ...userPatch } : u;
            });
        },
        /** Utility function to update a group. */
        patchGroup(groupPatch) {
            groups.value = groups.value.map((g) => {
                return g.id === groupPatch.id ? { ...g, ...groupPatch } : g;
            });
        },
        /** Updates the list of owners of an action item adding a new one. */
        addOwnerToActionItem(itemId, userId) {
            const actionItem = actionItems.value.find((a) => a.id === itemId);
            if (!actionItem) {
                return;
            }
            const owners = actionItem.owners ?? [];
            const hasOwner = owners.some((o) => o.userId === userId);
            if (!hasOwner) {
                patchActionItem({
                    id: actionItem.id,
                    owners: [...owners, { actionItemId: actionItem.id, userId }]
                });
            }
        },
        /** Updates the list of owners of an action item removing a new one. */
        removeOwnerToActionItem(itemId, userId) {
            const actionItem = actionItems.value.find((a) => a.id === itemId);
            if (actionItem) {
                const owners = actionItem.owners ?? [];
                patchActionItem({
                    id: actionItem.id,
                    owners: owners.filter((o) => o.userId !== userId)
                });
            }
        },
        /** Utility function to add a topic preventing duplications. */
        addTopicIfNotExists(topic) {
            const existingTopic = topics.value.find((t) => t.id === topic.id);
            if (!existingTopic) {
                topics.value.push(topic);
            }
        },
        /** Utility function to add a user preventing duplications. */
        addUserIfNotExists(user) {
            const existingUser = users.value.find((u) => u.id === user.id);
            if (!existingUser) {
                users.value.push(user);
            }
        },
        /** Utility to add a reaction without duplications. */
        addTopicReactionIfNotExists(reaction) {
            const topic = topics.value.find((t) => t.id === reaction.topicId);
            if (topic) {
                const existingReaction = topic.reactions.find((r) => r.userId === reaction.userId && r.type === reaction.type);
                if (!existingReaction) {
                    patchTopic({ id: topic.id, reactions: [...topic.reactions, reaction] });
                }
            }
        },
        /**
         * Utility function to clear data related to the sharing status.
         *
         * Useful because at the day of writing this there are more than one event
         * that trigger this behaviour. Kinda problematic.
         */
        clearSharingStatus() {
            topicBeingSharedId.value = null;
            sharingMode.value = null;
        },
        /** Utility function to clear action item writing/editing locks. */
        clearActionItemWritingStatus() {
            userWritingActionItemId.value = null;
            actionItemBeingEditedId.value = null;
            actionItemRealtimeValue.value = '';
        },
        /** Mark something as "loading". */
        putLoadingStatus(status) {
            loadingStatuses.value.add(status);
        },
        /** Unmark something that was "loading". */
        removeLoadingStatus(status) {
            loadingStatuses.value.delete(status);
        },
        /**
         * Clear all loading statuses.
         *
         * Useful to release locks on unexpected errors.
         */
        abortAllLoadingStatuses() {
            loadingStatuses.value.clear();
        }
    };
}
