import { computed } from 'vue';
import { useStore } from 'vuex';
import moment from 'moment'; // TODO: Do not use moment.
import { useConstantStore } from '@/pinia_stores/constant';
import constants from '@/constants';
export function useGetters(state) {
    const { actionItemBeingEditedId, actionItems, config, currentGroupNumber, currentPosterId, groups, isJoined, loadingStatuses, phase, topicBeingDeletedId, topicBeingEditedId, topicBeingSharedId, topics, users, userWritingActionItemId } = state;
    const constantStore = useConstantStore();
    const vuexStore = useStore();
    const userId = computed(() => vuexStore.getters.session.id);
    const retroUser = computed(() => users.value.find((u) => u.id === userId.value) || null);
    // The moderator is the only watcher so... do we need to preserve this?
    const watcher = computed(() => users.value.find((u) => !u.participant && !u.inWaitingRoom) ?? null);
    const isParticipant = computed(() => !!retroUser.value?.participant);
    const isModerator = computed(() => !!retroUser.value?.moderator);
    // the vuex getter had a check for the waiting room, sort and added a
    // REQUIRED `badgeType` to the participant...
    const participants = computed(() => users.value.filter((u) => u.participant));
    const motivation = computed(() => retroUser.value?.motivation ?? null);
    const hasMotivation = computed(() => motivation.value !== null);
    const startsAt = computed(() => config.value?.startsAt ?? null);
    const endsAt = computed(() => config.value?.endsAt ?? null);
    const postingDuration = computed(() => config.value?.postingDuration ?? null);
    const ownTopics = computed(() => topics.value.filter((t) => t.userId === userId.value));
    const topicBeingEdited = computed(() => topics.value.find((t) => t.id === topicBeingEditedId.value) || null);
    const isEditingTopic = computed(() => !!topicBeingEdited.value);
    const currentPoster = computed(() => users.value.find((u) => u.id === currentPosterId.value) ?? null);
    const topicBeingShared = computed(() => topics.value.find((t) => t.id === topicBeingSharedId.value ?? null));
    const currentGroup = computed(() => {
        if (currentGroupNumber.value !== null) {
            return groups.value.find((g) => g.number === currentGroupNumber.value) ?? null;
        }
        return null;
    });
    const userWritingActionItem = computed(() => {
        return users.value.find((u) => u.id === userWritingActionItemId.value) ?? null;
    });
    const actionItemBeingEdited = computed(() => {
        return actionItems.value.find((a) => a.id === actionItemBeingEditedId.value) ?? null;
    });
    const isCurrentUserWritingActionItem = computed(() => {
        const userWritingId = userWritingActionItemId.value;
        return userWritingId !== null && userWritingId === userId.value;
    });
    const isOtherUserWritingActionItem = computed(() => {
        const userWritingId = userWritingActionItemId.value;
        return userWritingId !== null && userWritingId !== userId.value;
    });
    const isActionItemBeingEdited = computed(() => !!actionItemBeingEdited.value);
    const isDoneWriting = computed(() => retroUser.value?.doneWriting || false);
    return {
        isModerator,
        userId,
        isActionItemBeingEdited,
        isOtherUserWritingActionItem,
        isCurrentUserWritingActionItem,
        actionItemBeingEdited,
        userWritingActionItem,
        currentGroup,
        topicBeingShared,
        currentPoster,
        isEditingTopic,
        topicBeingEdited,
        ownTopics,
        postingDuration,
        endsAt,
        startsAt,
        hasMotivation,
        motivation,
        participants,
        isParticipant,
        watcher,
        retroUser,
        isDoneWriting,
        isLoaded: computed(() => isJoined.value),
        isRetroUser: computed(() => !!retroUser.value),
        isOnlyModerator: computed(() => isModerator.value && !isParticipant.value),
        isWatcher: computed(() => watcher.value?.id === userId.value),
        connectedParticipants: computed(() => users.value.filter((u) => u.connected && u.participant)),
        phaseNumber: computed(() => constants.RETRO_PHASES.indexOf(phase.value) + 1),
        callLink: computed(() => config.value?.callLink ?? null),
        writingDuration: computed(() => config.value?.writingDuration ?? null),
        // Vuex getter had a sort based on insertedAt that should go in the view I suppose.
        ownPrivateTopics: computed(() => ownTopics.value.filter((t) => !t.public)),
        activity: computed(() => constantStore.activities.find((a) => a.id === config.value?.activity)),
        isDoneVoting: computed(() => retroUser.value?.doneVoting || false),
        // If not `false`, is considered "ok with groups", even if null (which seems
        // to mean "answer not submited by the user yet").
        isOkWithGroups: computed(() => retroUser.value?.okWithGroups !== false),
        isMotivationPending: computed(() => isParticipant.value && !hasMotivation.value),
        // Vuex getter had a sort based on the topic `position`. Seems like a
        // sort that should go in the view.
        publicTopics: computed(() => topics.value.filter((t) => t.public)),
        isWritingTopic: computed(() => phase.value === 'writing' &&
            !isDoneWriting.value &&
            !isEditingTopic.value &&
            topicBeingDeletedId.value === null),
        maxTopics: computed(() => config.value?.maxTopics ?? null),
        isCurrentUserPosting: computed(() => currentPosterId.value === userId.value),
        isOtherUserPosting: computed(() => !!currentPoster.value && currentPosterId.value !== userId.value),
        isOtherUserSharing: computed(() => {
            return topicBeingShared.value ? topicBeingShared.value.userId !== userId.value : false;
        }),
        votingMechanic: computed(() => config.value?.votingMechanic ?? null),
        expectedDurationInMinutes: computed(() => {
            if (startsAt.value && endsAt.value) {
                return moment(endsAt.value).diff(moment(startsAt.value), 'minutes');
            }
            return null;
        }),
        expectedPostingTurnDurationInMinutes: computed(() => {
            return postingDuration.value ? postingDuration.value / participants.value.length : null;
        }),
        isActionItemWritingIdle: computed(() => !isCurrentUserWritingActionItem.value && !isOtherUserWritingActionItem.value),
        currentGroupActionItems: computed(() => {
            if (currentGroup.value) {
                return actionItems.value.filter((a) => a.groupId === currentGroup.value.id);
            }
            return [];
        }),
        currentGroupTopics: computed(() => {
            if (currentGroup.value) {
                return topics.value.filter((t) => t.groupId === currentGroup.value.id);
            }
            return [];
        }),
        isNewActionItemBeingWritten: computed(() => !!userWritingActionItem.value && !isActionItemBeingEdited.value),
        retroName: computed(() => config.value?.name || null),
        isSomeActionLoading: computed(() => !!loadingStatuses.value.size)
    };
}
