import { ref } from 'vue';
export function useState() {
    return {
        // Connection control.
        socket: ref(null),
        channel: ref(null),
        isJoined: ref(false),
        isFull: ref(false),
        hasJoinedTheRetro: ref(false),
        // Remote static data.
        config: ref(null),
        teamId: ref(null),
        previousActionItems: ref([]),
        // Remote resource collections.
        actionItems: ref([]),
        groups: ref([]),
        topics: ref([]),
        users: ref([]),
        invitations: ref([]),
        // Other remote state
        phase: ref('setup'),
        actionItemBeingEditedId: ref(null),
        currentGroupNumber: ref(null),
        currentPosterId: ref(null),
        finishedAt: ref(null),
        iceBreakerAnswers: ref([]),
        iceBreakerQuestion: ref(null),
        postingStartedAt: ref(null),
        topicBeingSharedId: ref(null),
        turnStartedAt: ref(null),
        userWritingActionItemId: ref(null),
        writingStartedAt: ref(null),
        // TODO: The server does not send this data on join, so on refresh, this is
        // lost.
        currentPosterAmountOfTopics: ref(0),
        actionItemRealtimeValue: ref(''),
        // Local only state.
        topicBeingEditedId: ref(null),
        topicBeingDeletedId: ref(null),
        isReadyToShare: ref(false),
        sharingMode: ref(),
        progressModal: ref(null),
        loadingStatuses: ref(new Set()),
        isPreparingIceBreaker: ref(false),
        isInviteModalVisible: ref(false)
    };
}
