import { computed, onBeforeMount, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
/**
 * Composable used when accessing a workspace inside a component.
 *
 * It handles loading and unloading it from the store, as well as providing a
 * computed getter.
 *
 * Can be safely used even if the route is missing data. In this case, the
 * composable will do nothing.
 */
export function useWorkspace() {
    const route = useRoute();
    const store = useStore();
    onBeforeMount(() => {
        const { workspaceId } = route.params;
        if (!workspaceId) {
            return;
        }
        store.dispatch('loadWorkspaceScope', workspaceId);
    });
    onUnmounted(() => {
        store.dispatch('clearWorkspacesScope');
    });
    const workspace = computed(() => store.getters.workspace);
    return { workspace };
}
/**
 * Composable used when accessing a team inside a component.
 *
 * It handles loading and unloading it from the store, as well as providing the
 * necessary computed getters.
 *
 * Can be safely used even if the route is missing data. In this case, the
 * composable will do nothing.
 */
export function useWorkspaceAndTeam(params) {
    const route = useRoute();
    const store = useStore();
    onBeforeMount(() => {
        const { workspaceId, teamId } = route.params;
        if (!workspaceId || !teamId) {
            return;
        }
        store.dispatch('loadTeamAndWorkspaceScope', {
            workspaceId,
            teamId,
            loadFullWorkspace: params?.loadFullWorkspace || false
        });
    });
    onUnmounted(() => {
        store.dispatch('clearWorkspacesScope');
    });
    const team = computed(() => store.getters.team);
    const workspace = computed(() => store.getters.workspace);
    return { team, workspace };
}
